import React, { cloneElement } from 'react';
import { Link } from 'components/link/Link';

import s from './PartnersItem.scss';

interface IProps {
  name: string;
  link: string;
  children: React.ReactNode;
}

export const PartnersItem = ({ name, link, children }: IProps) => (
  <div className={s('partnersItem')}>
    <Link title={name} to={link} className={s.partnersItem__inner}>
     {cloneElement(children, {
       className: s.partnersItem__src,
     })}
    </Link>
  </div>
);
