import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { UIContext } from 'context/ui';

import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import DownArrow from 'assets/svg/icons/down-arrow.svg';

import s from './ScrollIndicator.scss';

export const ScrollIndicator = (props: { static?: boolean, text?: string }) => {

  const [scrolled, setScrolled] = useState(false);
  const { isMobile, headerTheme } = useContext<any>(UIContext);

  useEffect(() => {
    if (typeof window === undefined) {
      return;
    }

    setScrolled(window.pageYOffset > 0);
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <>
    <div className={s('scrollIndicator', headerTheme, { scrolled, static: props.static })}>
      <div className={s.scrollIndicator__inner}>
        <DownArrow className={s.scrollIndicator__arrow} />
        <span className={s.scrollIndicator__text}>
          {props.text ? props.text : <FormattedMessage id="scroll" />}
        </span>
      </div>
      <ViewportEnter
        threshold={1}
        once={false}
        onEnter={() => { setScrolled(false); }}
        onExit={() => {setScrolled(true); }}
      >
        <div className={s.scrollIndicator__scrolled} />
      </ViewportEnter>
    </div>

  </>
  );

};
