import React from 'react';

import Meta from 'components/meta/Meta';

import { Header } from 'components/kranavatn/header/Header';
import { Image } from 'components/image/Image';
import { Partners } from 'components/partners/Partners';
import { PartnersItem } from 'components/partners/PartnersItem';

import BlueLagoon from 'assets/svg/partners/blue-lagoon.svg';
import Grayline from 'assets/svg/partners/grayline.svg';
import HotelOdinsve from 'assets/svg/partners/hotel-odinsve.svg';
import Icelandair from 'assets/svg/partners/icelandair.svg';
import PromoteIceland from 'assets/svg/partners/promote-iceland.svg';
import MountainGuides from 'assets/svg/partners/mountain-guides.svg';
import Isavia from 'assets/svg/partners/isavia.svg';
import Seatours from 'assets/svg/partners/seatours.svg';
import SixtySixNorth from 'assets/svg/partners/sixty-six-north.svg';
import SpecialTours from 'assets/svg/partners/special-tours.svg';
import VisitWestfjords from 'assets/svg/partners/visit-westfjords.svg';
import { PageHeader } from 'components/page-header/PageHeader';
import { Segment } from 'components/layout/Segment';

export default () => {

  const metaDescription = 'Our partners; A list of official Inspired by Iceland partner companies.';

  const partnersList = [
    {link: 'https://icelandair.com', name: 'Icelandair', image: <Icelandair />},
    {link: 'https://bluelagoon.com', name: 'Blue Lagoon', image: <BlueLagoon />},
    {
      link: 'https://www.icelandtravel.is/',
      name: 'Iceland Travel',
      image: <Image src={require('assets/images/partners/iceland-travel.png')} width={72} height={97} />,
    },
    {
      link: 'https://mountainguides.is/',
      name: 'Icelandic Mountain Guides',
      image: <MountainGuides />,
    },
    {
      link: 'https://elding.is/',
      name: 'Elding - The Whale Watching Pioneers',
      image: <Image src={require('assets/images/partners/elding.png')} width={120} height={67} />,
    },
    {
      link: 'https://icelandiclamb.is/',
      name: 'Icelandic Lamb',
      image: <Image src={require('assets/images/partners/icelandic-lamb.png')} width={123} height={98} />,
    },
    {
      link: 'https://www.66north.com/',
      name: '66° North',
      image: <SixtySixNorth />,
    },
    {
      link: 'https://www.re.is/',
      name: 'Reykjavik Excursions',
      image: <Image src={require('assets/images/partners/reykjavik-excursions.png')} width={150} height={54} />,
    },
    {
      link: 'https://www.heyiceland.is/',
      name: 'Hey Iceland',
      image: <Image src={require('assets/images/partners/hey-iceland.png')} width={90} height={90} />,
    },
    {
      link: 'https://www.airicelandconnect.com/',
      name: 'Air Iceland Connect',
      image: <Image src={require('assets/images/partners/air-iceland-connect.png')} width={155} height={36} />,
    },
    {
      link: 'http://www.katla-dmi.is/en/',
      name: 'Katla DMI',
      image: <Image src={require('assets/images/partners/katla-dmi.png')} width={84} height={102} />,
    },
    {
      link: 'https://adventures.is/',
      name: 'Arctic Adventures',
      image: <Image src={require('assets/images/partners/arctic-adventures.png')} width={92} height={91} />,
    },
    {
      link: 'https://www.si.is/',
      name: 'Samtök Iðnaðarins',
      image: <Image src={require('assets/images/partners/si.png')} width={140} height={39} />,
    },
    {
      link: 'http://icelagoon.is/',
      name: 'Glacier Lagoon',
      image: <Image src={require('assets/images/partners/glacier-lagoon.png')} width={115} height={67} />,
    },
    {
      link: 'https://www.hotellaki.is/',
      name: 'Hotel Laki',
      image: <Image src={require('assets/images/partners/hotel-laki.png')} width={120} height={62} />,
    },
    {
      link: 'https://www.tour.is/',
      name: 'Tour.is',
      image: <Image src={require('assets/images/partners/tour-is.png')} width={160} height={29} />,
    },
    {
      link: 'https://www.seatours.is/',
      name: 'Seatours Eimskip',
      image: <Seatours />,
    },
    {
      link: 'https://www.flyovericeland.com/',
      name: 'Fly Over Iceland',
      image: <Image src={require('assets/images/partners/flyover-iceland.png')} width={125} height={49} />,
    },
    {
      link: 'https://www.hotelodinsve.is/',
      name: 'Hotel Óðinsvé',
      image: <HotelOdinsve />,
    },
    {
      link: 'https://www.hotelranga.is/',
      name: 'Hotel Rangá',
      image: <Image src={require('assets/images/partners/hotel-ranga.png')} width={160} height={51} />,
    },
    {
      link: 'https://specialtours.is/',
      name: 'Special Tours',
      image: <SpecialTours />,
    },
    {
      link: 'https://grayline.is/',
      name: 'Grayline Iceland',
      image: <Grayline />,
    },
    {
      link: 'https://grayline.is/',
      name: 'Snæland Travel',
      image: <Image src={require('assets/images/partners/snaeland-travel.png')} width={150} height={60} />,
    },
    {
      link: 'https://www.icelandairhotels.com/',
      name: 'Icelandair Hotels',
      image: <Image src={require('assets/images/partners/icelandair-hotels.png')} width={160} height={26} />,
    },
    {
      link: 'https://www.nordicvisitor.com/',
      name: 'Nordic Visitor',
      image: <Image src={require('assets/images/partners/nordic-visitor.png')} width={130} height={67} />,
    },
    {
      link: 'https://perlan.is/',
      name: 'Perlan',
      image: <Image src={require('assets/images/partners/perlan.png')} width={140} height={47} />,
    },
    {
      link: 'https://www.radissonblu.com/en/sagahotel-reykjavik',
      name: 'Radisson Blu Saga Hotel, Reykjavík',
      image: <Image src={require('assets/images/partners/radisson.png')} width={145} height={45} />,
    },
    {
      link: 'https://www.east.is/is',
      name: 'Austurland',
      image: <Image src={require('assets/images/partners/austurland.png')} width={120} height={54} />,
    },
    {link: 'https://www.westfjords.is/', name: 'Visit Westfjörds', image: <VisitWestfjords />},
    {
      link: 'https://www.stjornarradid.is/raduneyti/atvinnuvega-og-nyskopunarraduneytid/',
      name: 'Atvinnuvega og Nýsköpunarráðuneyti Íslands',
      image: <Image src={require('assets/images/partners/atvinnuvega.png')} width={155} height={98} />,
    },
    {
      link: 'https://www.saf.is/en/',
      name: 'SAF - The Icelandic Travel Industry Association',
      image: <Image src={require('assets/images/partners/saf.png')} width={63} height={75} />,
    },
    {
      link: 'https://www.islandsstofa.is/en',
      name: 'Íslandsstofa - Promote Iceland',
      image: <PromoteIceland />,
    },
    {
      link: 'http://www.austurbru.is/',
      name: 'Áusturbrú',
      image: <Image src={require('assets/images/partners/austurbru.png')} width={95} height={66} />,
    },
    {
      link: 'https://www.northiceland.is/en',
      name: 'Visit North Iceland',
      image: <Image src={require('assets/images/partners/visit-north-iceland.png')} width={124} height={88} />,
    },
    {
      link: 'https://www.isavia.is/en',
      name: 'Isavia',
      image: <Isavia />,
    },
    {
      link: 'https://www.south.is/',
      name: 'Visit South Iceland',
      image: <Image src={require('assets/images/partners/visit-south-iceland.png')} width={125} height={81} />,
    },
    {
      link: 'https://www.visitreykjanes.is/',
      name: 'Visit Reykjanes',
      image: <Image src={require('assets/images/partners/visit-reykjanes.png')} width={160} height={53} />,
    },
    {
      link: 'https://www.west.is/is',
      name: 'Markaðsstofa Vesturlands',
      image: <Image src={require('assets/images/partners/markadasstofa-vesturlands.png')} width={130} height={57} />,
    },
    {
      link: 'https://visitreykjavik.is/',
      name: 'Visit Reykjavík / #reykjavikloves',
      image: <Image src={require('assets/images/partners/reykjavik-loves.png')} width={100} height={100} />,
    },
  ];

  return (
  <>
    <Meta
      title="Our partners"
      description={metaDescription}
      image="https://s3-eu-west-1.amazonaws.com/kranavatn/images/share-image.jpg"
      imageAlt={metaDescription}
    />

    <Header
      logoLink="https://inspiredbyiceland.com/"
      navLink="/"
      navLinkText="Back to Kranavatn"
    />

    <PageHeader
      heading="Official Inspired by Iceland Partners"
      text="Find a list of official Inspired by Iceland partner companies below."
    />

    <Segment container>
      <Partners>
        {partnersList.map((item, i) => (
          <PartnersItem link={item.link} name={item.name} key={`partners-${i}`}>
            {item.image}
          </PartnersItem>
        ))}
      </Partners>
    </Segment>
  </>
  );
};
