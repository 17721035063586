import React, { Children } from 'react';

import { Segment } from 'components/layout/Segment';
import { Container } from 'components/layout/Container';

import s from './Partners.scss';

interface IProps {
  title: string;
  align?: 'left' | 'right';
  children: React.ReactNode;
}

export const Partners = ({ title, align, children }: IProps) => {

  return (
    <div className={s('partners', align)}>
      {title && <h3 className={s.partners__heading}>{title}</h3>}
      <div className={s.partners__inner}>
        {Children.map(children, (child, i) => (
          <div className={s.partners__item} key={i}>
            {child}
          </div>))}
      </div>
    </div>
  );
};
